import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import WalletApiService from "@/core/services/api.service.wallet";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shopping-cities",
    data() {
        return {
            requiredRule: [v => !!v || "Field is required"],
            isSaving: false,
            shopId: null,
            cities: [],
            citiesToAdd: [],
            shopName: "",
            valid: false,
            search: "",
            addShoppingCityDisable: false,
            validTo: null,
            shoppingCities: [],
            citiesIsLoading: false,
            shoppingCitiesTableHeaders: [
                {text: this.$i18n.t ( "scs.city" ), value: "name", sortable: false},
                {text: this.$i18n.t ( "common.delete" ), value: "delete", sortable: false}
            ],
        };
    },
    async created() {
        await WalletApiService.init();
        this.shopId = this.$route.params.shopId;
        this.shopName = this.$route.params.shopName;
        this.$log.debug("shopid: ", this.shopId);
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Shopping cities for shop: " + this.shopName}]);
        this.searchCities();
        this.retrieveShoppingCities();
    },

    methods: {
        searchCities() {
            // Items have already been loaded
            if (this.cities.length > 0) return;

            // Items have already been requested
            if (this.citiesIsLoading) return;

            this.citiesIsLoading = true;
            ApiService.query("api/cities")
                .then(res => {
                    this.cities = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.citiesIsLoading = false));
        },

        async delRecord(item) {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete city shopping for " + item.name +
                    " ?"
                )
            ) {
                this.deleteShoppingCity(item);
            }
        },
        deleteShoppingCity(item) {
            this.loading = true;
            if (item.id != null) {
                ApiService.delete(`api/shops/${this.shopId}/shopping-cities/${item.id}`)
                    .then(response => {
                        this.$log.debug("Response: ", response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.retrieveShoppingCities(), this.loading = false));
            }
        },
        retrieveShoppingCities() {
            if (this.shopId != null) {
                ApiService.get(`api/shops/${this.shopId}/shopping-cities`)
                    .then(response => {
                        this.$log.debug("Shopping cities: ", response.data);
                        this.shoppingCities = response.data;
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.loading = false));
            }
        },
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.saveShopTags(this.tags, this.shopId);
            }
        },
        addShoppingCity() {
            this.$log.debug("citiesToAdd: " + this.citiesToAdd);
            this.addShoppingCityDisable = true;

            ApiService.post(`api/shops/${this.shopId}/shopping-cities`,this.citiesToAdd)
                .then(response => {
                    this.$log.debug("Shopping city created: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.retrieveShoppingCities();
                    this.addShoppingCityDisable = false;
                    this.loading = false;
                    this.isSaving = false;
                    this.citiesToAdd = [];
                });
        }
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
